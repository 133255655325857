import axios from "axios";
import {
  CheckOutAdminRequestModel,
  checkPasswordModel,
  createCustomerModel,
  CustomerModel,
  CustomerSuccessResponse,
  EditShipmentModel,
  FilterModel,
  SalesOrderWithDetailModel,
  ShipmentModel,
} from "./customer.model";
import { endpoint } from "../../constants";
import { StringLiteral } from "typescript";
import moment from "moment/moment";

export default class CustomerApi {
  static createCustomer(createCustomer: CustomerModel) {
    return axios
      .post<any>(
        endpoint + "/api/Partner/CreateCustomer",
        createCustomer
      )
      .then((res) => res.data.data)
      .catch((err) => console.log(err.data));
      
  }

   static getCustomerList() {
     return axios
       .get<any>(endpoint + "/api/Partner/GetList")
       .then((res) => res.data.data);
   }

  static getSingleItem(customerId: string) {
    return axios
       .get<any>(endpoint +"/api/partner/GetSingleItem?id=" + customerId
       )
       .then((res) => res.data.data);
   }

   static updateCustomer(customer: CustomerModel) {
     return axios
       .post<any>(endpoint + "/api/partner/update", customer)
       .then((res) => res.data.data);
   }

   static updateShipment(customer: EditShipmentModel) {
    return axios
      .post<any>(endpoint + "/api/Shipment/updatePartial", customer)
      .then((res) => res.data.data);
  }

   static getCustomerListWithPaging(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/partner/GetListWithFilter", model)
      .then((res) => res.data.data);
  }

   static deleteCustomer(customerId: string) {
     return axios
       .post<any>(endpoint + "/api/partner/Delete?Id=" + customerId)
       .then((res) => [
         { response: res.data.data },
       ]);
   }

   static getProvince() {
    return axios
      .get<any>(endpoint + "/api/Address/GetProvinceByCountry?Id=76cb1ec3-2aab-499e-a2a2-ae2ce01e40e7")
      .then((res) => res.data.data);
  }

  static getCity(provinceId: string) {
    return axios
      .get<any>(endpoint + "/api/Address/GetCityByProvince?Id=" + provinceId)
      .then((res) => res.data.data);
  }

  static getDistrict(cityId: string) {
    return axios
      .get<any>(endpoint + "/api/Address/GetDistrictByCity?Id=" + cityId)
      .then((res) => res.data.data);
  }

  static checkPassword(checkPasswordModel: checkPasswordModel) {
    return axios
      .post<any>(endpoint + "/api/User/CheckPassword", checkPasswordModel)
      .then((res) => res.data);
  }

  static getCountSalesOrderDetailByPartner(partnerId: string) {
    return axios
      .get<any>(endpoint + "/api/SalesOrder/CountSalesOrderDetailByPartner?partnerId=" + partnerId)
      .then((res) => res.data.data);
  }

  static getSalesOrderByPartner(partnerId: string) {
    return axios
      .get<any>(endpoint + "/api/SalesOrder/GetListSalesOrderByPartner?partnerId=" + partnerId)
      .then((res) => res.data.data);
  }

  static getSingleSalesOrder(id: string) {
    return axios
      .get<any>(endpoint + "/api/SalesOrder/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static getShipmentBySO(salesOrderId: string) {
    return axios
      .get<any>(endpoint + "/api/Shipment/GetSingleSalesOrder?salesOrderId=" + salesOrderId)
      .then((res) => res.data.data);
  }

  static getSalesOrderDetail(salesOrderId: string) {
    return axios
      .get<any>(endpoint + "/api/SalesOrderDetail/GetListBySalesOrder?salesOrderId=" + salesOrderId)
      .then((res) => res.data.data);
  }

  static updateCustomerShipment(customer: ShipmentModel) {
    return axios
      .post<any>(endpoint + "/api/Shipment/update", customer)
      .then((res) => res.data.data);
  }

  static getSingleSalesOrderWithDetail(id:string) {
    return axios
      .get<any>(endpoint + "/api/SalesOrder/GetSingleWithDetail?id="+id)
      .then((res) => res.data.data);
  }

  static getListVariationByProduct(id: string) {
    return axios
      .get<any>(endpoint + "/api/Variation/GetListByProduct?productId=" + id)
      .then((res) => res.data.data);
  }

  static checkCostAdmin(model: CheckOutAdminRequestModel) {
    return axios
      .post<any>(endpoint + "/api/RajaOngkir/CheckCostAdmin", model)
      .then((res) => res.data);
  }

  static updateCheckOutAdmin(model: SalesOrderWithDetailModel) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/UpdateCheckOutAdmin", model)
      .then((res) => res.data);
  }

  static countPrintNote(salesOrderId: string) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/CountPrintNote?salesOrderId=" + salesOrderId)
      .then((res) => res.data);
  }

  static countPrintExpedition(salesOrderId: string) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/CountPrintExpedition?salesOrderId=" + salesOrderId)
      .then((res) => res.data);
  }

  static countPrintBackup(salesOrderId: string) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/CountPrintCadangan?salesOrderId=" + salesOrderId)
      .then((res) => res.data);
  }

  
  static countPrintSuratJalan(salesOrderId: string) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/CountPrintSuratJalan?salesOrderId=" + salesOrderId)
      .then((res) => res.data);
  }

  static getDeliveryReceiptById(id : string) {
    return axios
        .get<any>(endpoint + "/api/DeliveryReceipt/GetSingleBySONumber?sonumber="+id
        )
        .then((res) => res.data.data);
}

  static async ExportToExcel(model: FilterModel) {
    return axios
        .post<any>(
            endpoint + "/api/Partner/ExportPartnerToExcel",
            model,
            { responseType: "blob" }
        )
        .then((res: any) => {
          console.log(res);

          const url = URL.createObjectURL(new Blob([res.data]));

          console.log("url : " + url);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
              "download",
              `Partner - ${moment(new Date()).format(
                  "DD/MM/YYYY hh:mm:ss A"
              )}.xlsx`
          );

          document.body.appendChild(link);

          console.log("link : " + link);

          link.click();
        })
        .catch((err: any) => console.log(err));
  }
}
