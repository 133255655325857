import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../";
import {
  CityModel,
  CustomerModel,
  DistrictModel,
  ProvinceModel,
  createCustomerModel,
  SalesOrderCountModel,
  SalesOrderModel,
  ShipmentModel,
  SalesOrderDetailModel,
  SalesOrderWithDetailModel,
  ExpeditionServiceCostModel,
  CheckOutAdminRequestModel,
  FilterModel,
  EditShipmentModel,
} from "./customer.model";
import CustomerApi from "./customer.api";
import { variationModel } from "../master/product/product.model";
import { UserModel } from "../user/models";
import { Delivery } from "../confirmationReceipt/receiptDelivery/receiptDelivery.model";
import {exportToExcel} from "../Inventory/stockOpname/stockOpname.reducer";

export interface CustomerSlice {
  name?: string;
  list?: CustomerModel[];
  single?: CustomerModel;
  singleUserData: UserModel;
  singleCustomerData: CustomerModel;
  listProvince?: ProvinceModel[];
  listCity?: CityModel[];
  listDistrict?: DistrictModel[];
  SalesOrderCount?: SalesOrderCountModel;
  SalesOrderList?: SalesOrderModel[];
  totalRow?: number;
  singleSalesOrder: SalesOrderModel;
  singleSalesOrderWithDetail: SalesOrderWithDetailModel;
  singleShipment: ShipmentModel;
  listSalesOrderDetail: SalesOrderDetailModel[];
  expeditionList?: ExpeditionServiceCostModel[];
  listVariationByProduct?: variationModel[];
  deliveryReceiptData?: Delivery;
  isLoading?: boolean;
  isExportLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const createCustomer = createAsyncThunk(
  "createCustomerState/createCustomer",
  async (model: createCustomerModel, { rejectWithValue }) => {
    try {
      // const response = await CustomerApi.checkPassword(model.checkPasswordData);
      // if (response.status === true) {
      let customerModelData = model.customerData;
      return await CustomerApi.createCustomer(customerModelData);
      // }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getCustomerList = createAsyncThunk(
  "getCustomerListState/getCustomerList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await CustomerApi.getCustomerList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getCustomerListWithPaging = createAsyncThunk(
  "getCustomerListWithPagingState/getCustomerListWithPaging",
  async (model: FilterModel, { rejectWithValue }) => {
    try {
      return await CustomerApi.getCustomerListWithPaging(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const exportPartnerToExcel = createAsyncThunk(
    "customer/exportToExcel",
    async (model: FilterModel, { rejectWithValue }) => {
      try {
        return await CustomerApi.ExportToExcel(model);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const getDeliveryReceiptById = createAsyncThunk(
  "etDeliveryReceiptByIdState/etDeliveryReceiptById",
  async (id: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.getDeliveryReceiptById(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getCustomerSingleItem = createAsyncThunk(
  "CustomerSingleItemState/CustomerSingleItem",
  async (customerId: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.getSingleItem(customerId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "CustomerState/updateCustomer",
  async (model: createCustomerModel, { rejectWithValue }) => {
    try {
      // const response = await CustomerApi.checkPassword(model.checkPasswordData);
      // if (response.status === true) {
      //   let customerModelData = model.customerData;
      //   return await CustomerApi.updateCustomer(customerModelData);
      // }
      let customerModelData = model.customerData;
      return await CustomerApi.updateCustomer(customerModelData);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateShipment = createAsyncThunk(
  "CustomerState/updateShipment",
  async (model: EditShipmentModel, { rejectWithValue }) => {
    try {
      return await CustomerApi.updateShipment(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateCheckOutAdmin = createAsyncThunk(
  "updateCheckOutAdminState/updateCheckOutAdmin",
  async (model: SalesOrderWithDetailModel, { rejectWithValue }) => {
    try {
      return await CustomerApi.updateCheckOutAdmin(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "CustomerState/deleteCustomer",
  async (customerId: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.deleteCustomer(customerId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getProvince = createAsyncThunk(
  "ProvinceState/Province",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await CustomerApi.getProvince();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getCity = createAsyncThunk(
  "CityState/City",
  async (provinceId: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.getCity(provinceId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getDistrict = createAsyncThunk(
  "DistrictState/District",
  async (cityId: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.getDistrict(cityId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getCountSalesOrderDetail = createAsyncThunk(
  "CountSalesOrderDetailState/CountSalesOrderDetail",
  async (partnerId: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.getCountSalesOrderDetailByPartner(partnerId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSalesOrderByPartner = createAsyncThunk(
  "SalesOrderByPartnerState/SalesOrderByPartner",
  async (partnerId: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.getSalesOrderByPartner(partnerId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleSalesOrder = createAsyncThunk(
  "SinglesalesOrderState/SingleSalesOrder",
  async (id: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.getSingleSalesOrder(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getShipmentBySalesOrder = createAsyncThunk(
  "ShipmetBySalesORderState/ShipmentBySalesOrder",
  async (salesOrderId: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.getShipmentBySO(salesOrderId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSalesOrderDetailList = createAsyncThunk(
  "SalesOrderDetailListState/SalesOrderDetailList",
  async (salesOrderId: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.getSalesOrderDetail(salesOrderId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateCustomerShipment = createAsyncThunk(
  "UpdateCustomeShipentModelState/UpdateCustomeShipentModel",
  async (model: ShipmentModel, { rejectWithValue }) => {
    try {
      return await CustomerApi.updateCustomerShipment(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleSalesOrderWithDetail = createAsyncThunk(
  "getSingleSalesOrderWithDetailState/getSingleSalesOrderWithDetail",
  async (id: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.getSingleSalesOrderWithDetail(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListVariationByProduct = createAsyncThunk(
  "SalesOrderState/getVariationByproductList",
  async (id: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.getListVariationByProduct(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const checkCostAdmin = createAsyncThunk(
  "CheckCostAdminState/CheckCostAdmin",
  async (Model: CheckOutAdminRequestModel, { rejectWithValue }) => {
    try {
      return await CustomerApi.checkCostAdmin(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const countSOPrint = createAsyncThunk(
  "countSoPrintState/countSOPrint",
  async (id: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.countPrintNote(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const countExpeditionPrint = createAsyncThunk(
  "countExpeditionState/countExpedition",
  async (id: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.countPrintExpedition(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const countBackupPrint = createAsyncThunk(
  "countBackupState/countBackup",
  async (id: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.countPrintBackup(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const countSuratJalanPrint = createAsyncThunk(
  "countSuratJalanState/countSuratJalan",
  async (id: string, { rejectWithValue }) => {
    try {
      return await CustomerApi.countPrintSuratJalan(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const CustomerSlice = createSlice({
  name: "CustomerState",
  initialState: {} as CustomerSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as CustomerModel;
      state.status = "";
      state.singleUserData = {} as UserModel;
      state.singleSalesOrderWithDetail = {} as SalesOrderWithDetailModel;
    },
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCustomer.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(createCustomer.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createCustomer.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(getCustomerSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getCustomerSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload.partner;
      state.singleUserData = payload.user;
      state.singleCustomerData = payload.user;
      state.isLoading = false;
    });
    builder.addCase(getCustomerSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getCustomerList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getCustomerList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateCustomer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCustomer.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success";
      state.name = new Date().toISOString();
    });
    builder.addCase(updateCustomer.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(updateShipment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateShipment.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-update-shipment";
      state.name = new Date().toISOString();
    });
    builder.addCase(updateShipment.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed-update-shipment";
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(updateCheckOutAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCheckOutAdmin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-update-so";
      state.name = new Date().toISOString();
    });
    builder.addCase(updateCheckOutAdmin.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(deleteCustomer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCustomer.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = "";
      state.status = "success-delete";
    });
    builder.addCase(deleteCustomer.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.name = new Date().toISOString();
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getProvince.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProvince.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.listProvince = payload;
    });
    builder.addCase(getProvince.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getCity.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCity.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.listCity = payload;
    });
    builder.addCase(getCity.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getDistrict.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDistrict.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.listDistrict = payload;
    });
    builder.addCase(getDistrict.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getCountSalesOrderDetail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getCountSalesOrderDetail.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.SalesOrderCount = payload;
      }
    );
    builder.addCase(getCountSalesOrderDetail.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getSalesOrderByPartner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSalesOrderByPartner.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.SalesOrderList = payload;
    });
    builder.addCase(getSalesOrderByPartner.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getSingleSalesOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSingleSalesOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.singleSalesOrder = payload;
    });
    builder.addCase(getSingleSalesOrder.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getShipmentBySalesOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getShipmentBySalesOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.singleShipment = payload;
    });
    builder.addCase(getShipmentBySalesOrder.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getSalesOrderDetailList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSalesOrderDetailList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.listSalesOrderDetail = payload;
    });
    builder.addCase(getSalesOrderDetailList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(updateCustomerShipment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCustomerShipment.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-update-shipment";
    });
    builder.addCase(updateCustomerShipment.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(getSingleSalesOrderWithDetail.pending, (state) => {
      state.singleSalesOrderWithDetail = {} as SalesOrderWithDetailModel;
      state.isLoading = true;
    });
    builder.addCase(
      getSingleSalesOrderWithDetail.fulfilled,
      (state, { payload }) => {
        state.singleSalesOrderWithDetail = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getSingleSalesOrderWithDetail.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );

    builder.addCase(getListVariationByProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getListVariationByProduct.fulfilled,
      (state, { payload }) => {
        state.listVariationByProduct = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getListVariationByProduct.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );

    builder.addCase(checkCostAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(checkCostAdmin.fulfilled, (state, { payload }) => {
      state.expeditionList = payload.data;
      state.isLoading = false;
    });
    builder.addCase(checkCostAdmin.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getCustomerListWithPaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getCustomerListWithPaging.fulfilled,
      (state, { payload }) => {
        state.list = payload.list;
        state.totalRow = payload.total;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getCustomerListWithPaging.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );

    builder.addCase(getDeliveryReceiptById.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getDeliveryReceiptById.fulfilled, (state, { payload }) => {
      state.deliveryReceiptData = payload;
      state.isLoading = false;
    });
    builder.addCase(getDeliveryReceiptById.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(exportPartnerToExcel.pending, (state) => {
      state.isExportLoading = true;
    });
    builder.addCase(exportPartnerToExcel.fulfilled, (state, { payload }) => {
      state.isExportLoading = false;
      // state.exportResponse = payload
      state.status="success-export"
    });
    builder.addCase(exportPartnerToExcel.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isExportLoading = false;
    });
  },
});

export const { setFilter, resetSingle, resetStatus } = CustomerSlice.actions;
export default CustomerSlice.reducer;
